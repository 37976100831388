const english_lang = "en-us";
const supportedLang = [
  { name: "Arabic", native: "عربي", code1: "ar", code2: "ar-sa" },
  { name: "English", native: "English", code1: "en", code2: "en-us" },
];
export const language = {
  current: window.localStorage.getItem("userLang") || english_lang,
  supportedList: supportedLang,
  direction: window.localStorage.getItem("dir") || "ltr",
  dir_right: Boolean(window.localStorage.getItem("dir") === "rtl"),
  dir_left: Boolean(window.localStorage.getItem("dir") !== "rtl"),
};
