import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
//import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./controler/helpers/i18n";
import { language } from "./controler/globals/language";
import { StylesProvider, jssPreset } from "@material-ui/core/styles";
import { create } from "jss";
import rtl from "jss-rtl";
import { SnackbarProvider } from "notistack";

const App = React.lazy(() => import("./App"));
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
document.getElementById("root").dir = language.direction;

ReactDOM.render(
  <React.Fragment>
    <Suspense
      fallback={
        <div style={{ textAlign: "center", padding: 50 }}>loading website</div>
      }
    >
      <StylesProvider jss={jss}>
        <SnackbarProvider maxSnack={3}>
          <App />
        </SnackbarProvider>
      </StylesProvider>
    </Suspense>
  </React.Fragment>,
  document.getElementById("root")
);
reportWebVitals();
